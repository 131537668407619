export default [
  {
    header: 'REPORT and ANALYSIS',
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'TrendingUpIcon',
    resource: 'Dashboard',
  },
  {
    title: 'Report',
    route: 'report',
    icon: 'FileTextIcon',
    resource: 'Agent',
  },
  {
    title: 'Member & Agents',
    route: 'agent-list',
    icon: 'UsersIcon',
    resource: 'Company',
  },
]
